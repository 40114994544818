export const blogData = [];
export const services = [
  {
    title: "REVENUE COLLECTION",
    subtitle:
      "Enables generation of invoices for customers based on specific needs, and is integrated with the chart of accounts enable accurate referencing of charge codes and for reconciliation",
    image_url: "images/home/bills.jpg",
  },

  {
    title: "Receipting",
    subtitle:
      "Provides receipts for all payments received as proof of payment.Each type of payment will have its own customized receipts. Each department has its own portal for service delivery.",
    image_url: "images/home/receipts.jpg",
  },
  {
    title: "Reporting",
    subtitle:
      "Real-time dashboards report on revenue collection and management activities, displaying analytical data to show revenue management status for each department.",
    image_url: "images/home/Data-and-Analytics.jpg",
  },
  {
    title: "Revenue Collection",
    subtitle:
      " Provides channels of collection for both structured and unstructured revenue streams through mobile (App and USSD), web,devices (terminals), and agency networks (banks)",
    image_url: "images/home/revenue-collection.jpg",
  },
  {
    title: "Power Bi",
    subtitle:
      "Power BI suite delivers insights for clients by connecting to data sources, simplifying data prep, and providing a focused view of critical business data in a single place.",
    image_url: "images/home/Digital-Product-Engineering.jpg",
  },

  {
    title: "Back Office Operations",
    subtitle:
      "Our back-office solutions automate and simplify tasks, providing valuable data to optimize resources, balance workloads, and improve service quality within compliance.",
    image_url: "images/home/offer-1.png",
  },
];

export const researchProjects = [
  {
    title:
      " Hope for a better Future: Building Collaborative Resilience for Youth in Liberia",
    subtitle:
      "  We also launched the Mary Antoinette Brown Sherman Think Tank, which will strengthen the position of girls and women in society through leadership and governance training programs, by providing a platform for discussion and through research.",
    image_url: "images/home/Monument.png",
  },
  {
    title:
      " Hope for a better Future: Building Collaborative Resilience for Youth in Liberia",
    subtitle:
      "  We also launched the Mary Antoinette Brown Sherman Think Tank, which will strengthen the position of girls and women in society through leadership and governance training programs, by providing a platform for discussion and through research.",
    image_url: "images/home/Monument.png",
  },
];

export const execTeam = [
  {
    name: "Lilian Ambani",
    title: "Financial Advisory Lead ",
    image: "images/about/linda.jpg",
    profile:
      "As a Financial expert with over 8 years of experience, I bring a wealth of knowledge and expertise to the table in Financial Advisory, Financial Reporting & Analysis, Accounting, Budgeting & Forecasting, Tax planning & compliance, Payroll Management, Cash flow management, Financial Modelling and Risk Management. My approach to delivering high-quality outputs is centered around meeting client objectives and deadlines. I understand the importance of building strong client relationships, and I'm committed to providing exceptional service and support. With a Master’s in Business Administration (MBA) in Finance, a Bachelor’s degree in Business Management, Accounting, and being a CPA finalist, I possess a deep understanding of financial principles and best practices. Additionally, as a Certified Project Management Professional (PMP) by the Project Management Institute (PMI), I have the skills to effectively manage complex projects and initiatives. ",
  },
  {
    name: "Peter Kathanga",
    title: "",
    image: "",
    profile:
      "Peter has over 30 years of experience in various ranks within Banking, Insurance and Co-operatives Sectors in Kenya.Until May 2021, he served as KCB’s Corporate Director in Kenya. In this role, he participated in structuring some of the country’s largest financial transactions involving import and export trade, roads construction, manufacturing, oil and gas, microfinance and government security equipment.Other leadership roles include:MD - KCB Insurance Agency Ltd, Head of SACCO Banking - KCB,Co-operatives Relationship Manager - Co-operative Bank,General Manager - CIC Insurance Company Ltd. Peter holds a Master of Business Administration degree from USIU-Africa and a Bachelor of Education degree (Economics and Business Education) from Kenyatta University.He has attended several courses in banking, Co-operatives, Insurance, IT and General Management, including the Best and Brightest Executive Credit Course for African Bankers in New York, USA sponsored by USAID.",
  },
  // {
  //   name: "Peter Kathanga",
  //   title: "",
  //   image: "",
  //   profile:
  //     "Peter has over 30 years of experience in various ranks within Banking, Insurance and Co-operatives Sectors in Kenya.Until May 2021, he served as KCB’s Corporate Director in Kenya. In this role, he participated in structuring some of the country’s largest financial transactions involving import and export trade, roads construction, manufacturing, oil and gas, microfinance and government security equipment.Other leadership roles include:MD - KCB Insurance Agency Ltd, Head of SACCO Banking - KCB,Co-operatives Relationship Manager - Co-operative Bank,General Manager - CIC Insurance Company Ltd. Peter holds a Master of Business Administration degree from USIU-Africa and a Bachelor of Education degree (Economics and Business Education) from Kenyatta University.He has attended several courses in banking, Co-operatives, Insurance, IT and General Management, including the Best and Brightest Executive Credit Course for African Bankers in New York, USA sponsored by USAID.",
  // },
  // {
  //   name: "Peter Kathanga",
  //   title: "",
  //   image: "",
  //   profile:
  //     "Peter has over 30 years of experience in various ranks within Banking, Insurance and Co-operatives Sectors in Kenya.Until May 2021, he served as KCB’s Corporate Director in Kenya. In this role, he participated in structuring some of the country’s largest financial transactions involving import and export trade, roads construction, manufacturing, oil and gas, microfinance and government security equipment.Other leadership roles include:MD - KCB Insurance Agency Ltd, Head of SACCO Banking - KCB,Co-operatives Relationship Manager - Co-operative Bank,General Manager - CIC Insurance Company Ltd. Peter holds a Master of Business Administration degree from USIU-Africa and a Bachelor of Education degree (Economics and Business Education) from Kenyatta University.He has attended several courses in banking, Co-operatives, Insurance, IT and General Management, including the Best and Brightest Executive Credit Course for African Bankers in New York, USA sponsored by USAID.",
  // },
];
export const technicalteam = [

  {
    name: "Fabian Muema",
    title: "Senior Software Engineer",
    image: "images/about/fabian.jpg",
    profile:
      " Fabian is a software developer with over 6 years of experience. He has a proven track record of delivering high-quality, innovative software solutions.He is highly proficient in programming languages such as PHP (Laravel & WordPress), Python, Bash Scripting and React Native.He’s well versed in Agile and Scrum development methodologies and has extensive experience integrating with third-party APIs and Services, DevOps and CI/CD toolsets.With a high success rate in delivering projects on time and within budget, he has contributed to the successful delivery of Government, Parastatal and East African Court of Justice projects.He has previously maintained and administered large computer networks with 5000+ users using Radius and RouterOS.His ultimate goal is to push the boundaries of what's possible with software and deliver outstanding results in every project he undertakes.",
  },
  {
    name: "Warren Ochieng",
    title: "Fullstack Developer",
    image: "images/about/warren.jpg",
    profile:
      "Warren is a skilled Node.js developer with 3 years of experience in web development. He has a strong command over various technologies like React, Vue.js, NoSQL and SQL databases, and payment integration. He also possesses a key eye on design, making him an asset for any project that demands both functionality and aesthetics. Warren has a proven track record of developing and delivering high-quality, scalable web applications that meet the needs of his clients. With his expertise and knowledge, he is capable of designing and developing robust web solutions that are tailored to meet the unique requirements of his clients. Warren is a dedicated professional who is always eager to learn new technologies and keep up-to-date with the latest trends in web development",
  },
];
export const valueProp = [{ title: "", desc: "", image: "" }];
