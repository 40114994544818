const ValuesProp = () => {
  return (
    <section className="bg-[#EEEEEE]">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:px-12">
          <div className="flex flex-col md:flex-row justify-around bg-[#89CCC9] md:h-[300px] p-4">
            <div class="value-card space-y-4">
              <h2>Simple and agile solutions</h2>
              <p>
                Frictionless and customized customer-centric solutions built to
                ensure minimum “remembering’ of details needed to make payments.
              </p>
            </div>
            <img src="images/home/collection.png" alt="image" loading="lazy" />
          </div>
          <div className="flex flex-col md:flex-row justify-around bg-[#81D8D6] md:h-[300px] p-4">
            <div class="value-card space-y-4">
              <h2>Scalability</h2>
              <p>
                No infrastructure investment. Short delivery timelines.
                Deployment can be both local and cloud-based
              </p>
            </div>
            <img src="images/home/scalable.png" alt="image" loading="lazy" />
          </div>
          <div className="flex flex-col md:flex-row justify-around bg-[#00ADC6] md:h-[300px] p-4">
            <div class="value-card space-y-4">
              <h2>Cognitive and intuitive solution</h2>
              <p>
                Intelligent insights from structured and unstructured data.
                Detailed reporting and customized dashboards to suit your needs.
              </p>
            </div>
            <img src="images/home/SH.png" alt="image" loading="lazy" />
          </div>
          <div className="flex flex-col md:flex-row justify-around bg-[#009CB3] md:h-[300px]">
            <div class="value-card space-y-4 p-4">
              <h2>GIS MAPPING</h2>
              <p>Mapped revenue streams form all your targeted customers.
                Spatial analysis of you revenue collection</p>
            </div>
            <img src="images/home/omnichannel.png" alt="image" loading="lazy" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:px-12">
          <div className="flex justify-around bg-[#F7BE6A] h-[300px]">
            <div class="value-card space-y-4 p-4">
              <h2>Easy integrations</h2>
              <p>
                Easy integrations One integration exposes our clients and their
                customers to all payments methods through APIs.
              </p>
            </div>
          </div>
          <div className="flex justify-around bg-[#FF8372] md:h-[300px]">
            <div class="value-card space-y-4 p-4">
              <h2>Automated reconciliation</h2>
              <p>
                Integration to our clients’ systems allow us to reconcile all
                payments and customer engagement interactions.
              </p>
            </div>
          </div>
          <div className="flex justify-around bg-[#9ECC7A] md:h-[300px]">
            <div class="value-card space-y-4 p-4">
              <h2>Omni-channel solutions</h2>
              <p>
                Intelligent insights from structured and unstructured data.
                Detailed reporting and customized dashboards to suit your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ValuesProp;
