import { useState, useEffect } from "react";
import { getData } from '../../services';
const Navbar = () => {
    const [navitems, setNavItems] = useState([]);
const [isOpen,setOpen] = useState(false);
const handleClick=()=>{
    setOpen(!isOpen)
}
useEffect(() => {
  getData('https://kacit.twafwane.com/wp-json/menus/v1/menus/main-menu').then((data)=>{
    setNavItems(data.items)
  })
}, []);
  return (
    <section>
        <header>
        <div className="header-container container">
            <div className="logo">
                <img src="images/home/logo-lg.png" alt="Kofi Annan  Annan Institute for Conflict Transformation Logo" className="h-[50px] object-contain"></img>
            </div>
            <div className="header-contacts hidden">
                <div className="contact contact-one hidden">
                    <div className="left pad">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25.0938 26.625C21.9583 26.625 18.9022 25.926 15.9252 24.5279C12.9473 23.1308 10.3101 21.2836 8.01375 18.9862C5.71639 16.6899 3.86917 14.0527 2.47208 11.0748C1.07403 8.09785 0.375 5.04167 0.375 1.90625C0.375 1.46875 0.520833 1.10417 0.8125 0.8125C1.10417 0.520833 1.46875 0.375 1.90625 0.375H7.8125C8.15278 0.375 8.4566 0.484375 8.72396 0.703125C8.99132 0.921875 9.14931 1.20139 9.19792 1.54167L10.1458 6.64583C10.1944 6.98611 10.1886 7.29576 10.1283 7.57479C10.0671 7.85479 9.92708 8.10417 9.70833 8.32292L6.20833 11.8958C7.22917 13.6458 8.50521 15.2865 10.0365 16.8177C11.5677 18.349 13.2569 19.6736 15.1042 20.7917L18.5312 17.3646C18.75 17.1458 19.0358 16.9815 19.3888 16.8717C19.7407 16.7628 20.0868 16.7326 20.4271 16.7812L25.4583 17.8021C25.7986 17.875 26.0781 18.0388 26.2969 18.2935C26.5156 18.5492 26.625 18.8472 26.625 19.1875V25.0938C26.625 25.5313 26.4792 25.8958 26.1875 26.1875C25.8958 26.4792 25.5313 26.625 25.0938 26.625Z"
                                fill="#1C1B1F" />
                        </svg>
                    </div>
                    <div className="right pad">
                        <p> 0112 774 227</p>
                    </div>
                </div>
                <div className="contact contact-one">
                    <div className="left pad">
                        <svg width="31" height="25" viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.83341 24.1667C3.03133 24.1667 2.34494 23.8813 1.77425 23.3106C1.20258 22.739 0.916748 22.0521 0.916748 21.25V3.74999C0.916748 2.94791 1.20258 2.26152 1.77425 1.69083C2.34494 1.11916 3.03133 0.833328 3.83341 0.833328H27.1667C27.9688 0.833328 28.6557 1.11916 29.2274 1.69083C29.7981 2.26152 30.0834 2.94791 30.0834 3.74999V21.25C30.0834 22.0521 29.7981 22.739 29.2274 23.3106C28.6557 23.8813 27.9688 24.1667 27.1667 24.1667H3.83341ZM15.5001 13.9583L27.1667 6.66666V3.74999L15.5001 11.0417L3.83341 3.74999V6.66666L15.5001 13.9583Z"
                                fill="#1C1B1F" />
                        </svg>
                    </div>
                    <div className="right pad">
                        <p>info@strydom.co.ke</p>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <nav>
        <div className="nav-container container">
            <div className="nav logo">
            <img src="images/home/logo-lg.png" alt="logo" className="h-[50px] object-contain"></img>
            </div>
            <div className={isOpen?'active links block':'hide links hidden'} id="links">
                <div className="cancel">
                    <span className={isOpen?'cancel-btn active block':'cancel-btn hide hidden'}  onClick={()=>setOpen(false)}><i className="fas fa-times"></i></span>
                </div>
                {/* {navitems.map((item)=>(
                    <a href={item.url} className="active">{item.title}</a>
                ))} */}
                 <a href="/">Home</a>
                <a href="about-us">About Us</a>
                <a href="contact-us">Contact Us</a>
             
            </div>
            <div className="menu-bar">
                <span className={isOpen?'menu-btn hide hidden':'menu-btn active block'} onClick={()=>setOpen(true)}><i className="fas fa-bars"></i></span>
            </div>
        </div>
    </nav>
    </section>
  );
};
export default Navbar;
