import { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { execTeam,technicalteam} from "../../Data/home";
const Team = () => {
  const [executive, setExec] = useState(false);
  const [open, setOpen] = useState(false);
const [member, setMember] = useState({});

  const onOpenModal = (data) =>{
    setMember(data)
    setOpen(true)
  };
  const onCloseModal = () => setOpen(false);

  return (
    <section class="team">
      <div class="team-container container">
        <div class="team-head">
          <h1>Our Team</h1>
        </div>
        <div className="flex items-start px-12">
        <button
            className={
              executive
                ? "text-[#c84802] text-24 bg-white"
                : "text-white text-24 bg-[#c84802]"
            }
            onClick={() => {
              setExec(false);
            }}
          >
            Technical Team
          </button>
          <button
            className={
              executive
                ? "text-white text-24 bg-[#c84802]"
                : "text-[#c84802] text-24 bg-white"
            }
            onClick={() => {
              setExec(true);
            }}
          >
            Executive Team
          </button>
         
        </div>
        {!executive && (
          <div class="team-content">
            {technicalteam.map((team)=>(
                 <div class="member">
                 <img
                   src={team.image}
                   alt="Kofi Annan  Annan Institute for Conflict Transformation Team Member"
                   loading="lazy"
                 />
                 <div class="member-info">
                   <h3>{team.name}</h3>
                   <span>{team.title}</span>
                <div className="text-container">
                <p>
                     {team.profile}...
                   </p>
                </div>
                   {/* <a href="#">Read More <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.4 12L0 10.6L4.6 6L0 1.4L1.4 0L7.4 6L1.4 12Z" fill="#25518C"/>
                                    </svg>
                                    </a> */}
                   <div className="flex items-center gap-5">
                     <i className="fa fa-facebook"></i>
                     <i className="fa fa-linkedin"></i>
                     <i className="fa fa-twitter"></i>
                     <a onClick={()=>{onOpenModal(team)}}>
                       View Profile
                       <svg
                         width="8"
                         height="12"
                         viewBox="0 0 8 12"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <path
                           d="M1.4 12L0 10.6L4.6 6L0 1.4L1.4 0L7.4 6L1.4 12Z"
                           fill="#25518C"
                         />
                       </svg>
                     </a>
                   </div>
                 </div>
               </div>
            ))}
           
            
            <div class="member"></div>
          </div>
        )}
        {executive && (
          <div class="team-content">
            {execTeam.map((team)=>(
                <div class="member">
                <img
                  src={team.image}
                  alt={team.name}
                  loading="lazy"
                />
                <div class="member-info">
                  <h3>{team.name}</h3>
                  <span>{team.title}</span>
                  <p>
                    {team.profile.slice(0,100)}...
                  </p>
                  {/* <a href="#">Read More <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <path d="M1.4 12L0 10.6L4.6 6L0 1.4L1.4 0L7.4 6L1.4 12Z" fill="#25518C"/>
                                   </svg>
                                   </a> */}
                  <div className="flex items-center gap-5">
                    <i className="fa fa-facebook"></i>
                    <i className="fa fa-linkedin"></i>
                    <i className="fa fa-twitter"></i>
                    <a onClick={()=>{onOpenModal(team)}}>
                      View Profile
                      <svg
                        width="8"
                        height="12"
                        viewBox="0 0 8 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.4 12L0 10.6L4.6 6L0 1.4L1.4 0L7.4 6L1.4 12Z"
                          fill="#25518C"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            ))}
            
            <div class="member"></div>
          </div>
        )}
        <Modal open={open} onClose={onCloseModal} center>
          <div className=" h-[640px] gap-10 w-fit">
            <img src={member.image} alt="" style={{maxHeight:'300px',objectFit:'contain',float:'left',margin:'10px'}}/>
            <p styler={{textAlign:'justify'}}>
              {member.profile}
            </p>
          </div>
        </Modal>
      </div>
    </section>
  );
};
export default Team;
