const Footer = () => {
  return (
    <footer>
      <div className="footer-container container">
        <div className="col one">
          <h4>STRYDOM</h4>
          <p>
            We are an integrated revenue collection and management solution
            provider with a bespoke 360-degree platform suite that supports all
            revenue collection activities such as billing, collection,
            management, enforcement and reporting of revenue service
          </p>
        </div>
        <div className="col two">
          <h4>Support</h4>
          <ul>
            <li>
              <a href="contact-us">Help & Support</a>
            </li>
            <li>
              <a href="contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className="col three">
          <h4>Company</h4>
          <ul>
            <li>
              <a href="about-us">About Us</a>
            </li>
            <li>
              <a href="#services">Services</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom-footer container">
        <p>
          Strydom. Rights Reserved.
          Copyright 2023.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
