const Mission=()=>{
    return (
        <section class="missions">
        <div class="missions-container container">
            <div class="mission one">
                <h2>Simple and agile solutions</h2>
                <p>Frictionless and customized customer-centric solutions built to ensure minimum “remembering’ of details needed to make payments.</p>
            </div>
            <div class="mission two">
                <h2>Scalability</h2>
                <p>No infrastructure investment. Short delivery timelines. Deployment can be both local and cloud-based</p>
            </div>
            <div class="mission three">
                <h2>Cognitive and intuitive solution</h2>
                <ul>
                    <li><span></span><span>Intelligent insights from structured and unstructured data. Detailed reporting and customized dashboards to suit your needs.</span></li>
                    {/* <li><span>02.</span><span>Respect for diversity</span></li>
                    <li><span>03.</span><span>Respect for diversity</span></li> */}
                </ul>
            </div>
        </div>
    </section>
    )

}
export default Mission