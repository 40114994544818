import './App.css';
import Navbar from "./components/utils/navbar";
import Hero from './components/home/hero';
import Services from "./components/home/services";
import ThinkTanks from './components/home/think-tanks';
import ContactUs from './components/home/contact';
import Footer from './components/utils/footer';
import EventSection from './components/home/events';
import ResearchProjects from './components/home/ResearchProjects';
import WhyUs from './components/about/why-us';
import Mission from "./components/about/mission"
import Team from "./components/about/team"
import ValuesProp from './components/home/value-prop';
function App() {

  return (
    <div className="App">
      <Navbar/>
      <main>
      <Hero/>
      <WhyUs/>
      <Services/>
     <ValuesProp/>
      <Team/>
      <ContactUs/>
      </main>
      <Footer/>
    </div>
  );
}

export default App;
