const Hero = () => {
  return (
    <section class="banner">
    <div class="banner-mask">
        <div class="banner-container container">
            <div class="banner-text">
                <h1> A bespoke 360-degree platform suite that supports all your revenue collection needs</h1>
                <p>Our revenue collection and management solution encompasses the whole revenue cycle from collection, management, and reporting with business intelligence capabilities. </p>
                <a href="#form"><button>BOOK A DEMO</button></a>
            </div>
        </div>
    </div>
</section>
  );
};
export default Hero;
