import {services} from "../../Data/home"
const Choices=()=>{
  return (
    <section class="choices">
    <div class="choices-mask">
        <div class="choices-container container">
            <div class="choice-head">
                <p>Why Choose Us</p>
                <h1>Focal Points</h1>
            </div>
            <div class="choice-content">
                <div class="row">
                    {services.map((service)=>(
                         <div class="column choice space-y-8">
                         <h3>{service.title}</h3>
                         <p>{service.subtitle}</p>
                     </div>
                    ))}
                   
                    <div class="column">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
 export default Choices
